import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import BootstrapVueNext from "bootstrap-vue-next";
import VueApexCharts from "vue3-apexcharts";
import { QuillEditor } from '@vueup/vue-quill'
import Vue3Prism from 'vue3-prism/lib/Vue3Prism.common.js'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "swiper/css";
import "swiper/css/bundle";
import 'flatpickr/dist/flatpickr.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import "vue3-prism/lib/Vue3Prism.css"

import "./assets/custom.scss";

import 'intl-tel-input/build/css/intlTelInput.css';
const hostname = window.location.hostname ;
let front = '';
let back = '';
//  alert(process.env.BASE_URL)
// alert(process.env.VUE_APP_API_VERSION)

if(process.env.VUE_APP_BASE_URL_PROD.includes(hostname)){
  front=process.env.VUE_APP_BASE_URL_PROD
  back=process.env.VUE_APP_API_BASE_PROD+process.env.VUE_APP_API_VERSION
    // alert(window.location)
  }


if(process.env.VUE_APP_BASE_URL_PREPROD.includes(hostname)){
  front=process.env.VUE_APP_BASE_URL_PREPROD
  back=process.env.VUE_APP_API_BASE_PREPROD+process.env.VUE_APP_API_VERSION
    // alert(window.location)
  }
  
  if(process.env.VUE_APP_BASE_URL_DEV.includes(hostname)){
    front=process.env.VUE_APP_BASE_URL_DEV
    back=process.env.VUE_APP_API_BASE_DEV+process.env.VUE_APP_API_VERSION
    // alert(window.location)
  }

  if(process.env.VUE_APP_BASE_URL_DEV_LOCAL.includes(hostname)){
    front=process.env.VUE_APP_BASE_URL_DEV_LOCAL
    back=process.env.VUE_APP_API_BASE_DEV_LOCAL+process.env.VUE_APP_API_VERSION
    
  }

  if(process.env.VUE_APP_BASE_URL_SANDBOX.includes(hostname)){
    front=process.env.VUE_APP_BASE_URL_SANDBOX
    back=process.env.VUE_APP_API_BASE_SANDBOX+process.env.VUE_APP_SANDBOX
    
  }


const app = createApp(App).use(router);
app.use(VueApexCharts);
app.use(BootstrapVueNext);
app.provide('front', front);
app.provide('back', back);
app.component('QuillEditor', QuillEditor)
.use(Vue3Prism)
app.mount("#app");
