<template>
  <div
    class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing"
  >
    <h4 class="mb-5 mb-sm-0 fw-bold">
      <router-link to="" @click="back" class="link-btn lh-1 text-decoration-none">
         <i class="ph ph-caret-left"></i>
      </router-link>
      {{ PageTitle }}  {{organization?.name }}    
    </h4>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0 list-unstyled ps-0">
        <li class="breadcrumb-item">
          <router-link to="/dashboard" class="text-decoration-none text-black">
            Tableau de bord
          </router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ PageTitle }}
        </li>
      </ol>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { defineComponent,defineProps } from "vue";
import {ref,onMounted } from "vue";
import { useRoute } from 'vue-router'
import axios from 'axios';
import conf from '../../../env.json'

const props = defineProps(["PageTitle","company"])

declare interface Organization {
      id : string,
      code: string,
      name: string,
      description: string,    
      email: string,
      created_at: string
      }
     
    const back = ()=>{
      window.history.back()
    }
 
    let code:string| string[]='';

    onMounted(() => {  
      const route = useRoute();
      code = route.params.id;
      getOrganization(code);
     //alert(code)
    });
    const token =  localStorage.getItem('login_token');
    const organization = ref({} as Organization)
    
    
      const getOrganization = (code) =>{

            axios.get(`${process.env.VUE_APP_API_BASE}/secure/organizations/${code}/show`,{
                  headers: {
                      Authorization: `Bearer ${token}`
                }
              })
              .then((response) => {
                  // users.value = response.data;
                  organization.value = response.data;  //.data.users   
                  
              }).catch((error) => {
                    // if( error.response){                       
                                               
                    // }
            });
        }
  
</script>