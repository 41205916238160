<template>
  <div class="row">
    
    <div class="col-xxl-8 order-2 order-xl-1">
      <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
        <div class="card-body p-10">
          <ul class="settings-tabs ps-0 mb-0 list-unstyled">
            <li>
              <router-link
                to="/account-settings"
                class="fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
              >
              Informations personnelles
              </router-link>
            </li>
            <li>
              <router-link
                to="/settings-change-password"
                class="fs-md-15 fs-lg-16 fw-medium text-decoration-none d-block w-100 rounded-1 text-center transition"
              >
              Modifier mon mot de passe
              </router-link>
            </li>           
          </ul>
        </div>
      </div>
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing settings-card"
      >
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <div v-if="errors!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
              <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
            </div>
            <div v-if="success!==''" style="margin-top: 5%;">
              <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
            </div>
          <form  ref="form" @submit.prevent="update_password">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Ancien mot de passe
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**********"
                v-model="formValues.oldpassword"
                @blur="validate('oldpassword')"
                @keyup="validate('oldpassword')"
                :class="{ 'is-invalid': errorsBis['oldpassword'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['oldpassword']}}</span>       
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Nouveau mot de passe
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**********"
                v-model="formValues.password"
                @blur="validate('password')"
                @keyup="validate('password')"
                :class="{ 'is-invalid': errorsBis['password'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['password']}}</span>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Confirmation du nouveau mot de passe
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**********"
                v-model="formValues.confirm_password"
                @blur="validate('confirm_password')"
                @keyup="validate('confirm_password')"
                :class="{ 'is-invalid': errorsBis['confirm_password'] }"
              />                   
              <span class="invalid-feedback">{{ errorsBis['confirm_password']}}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <button
                class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                type="submit"
                style="background-color: #EB9355;"  :disabled="!btnLoad"
              >
              <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
               Changer le mot de passe
              </button>
              <!-- bg-success -->
              <!-- <button
                type="button"
                class="bg-transparent p-0 border-0 text-danger lh-1 fw-medium"
              >
                <i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"
                ></i>
                <span class="position-relative">Cancel</span>
              </button> -->
            </div>
          </form>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script setup>
// export default {
//   name: "ChangePassword",
// };
import router from '@/router';
import axios from 'axios';
import { ref,onMounted, reactive,inject } from 'vue';
import { useRoute } from 'vue-router'
import * as yup from 'yup';

const backApi=inject('back');
const errors = ref('');
const errorId = ref('');
const success = ref('');
const btnLoad = ref(true);

const errorsBis = {'oldpassword':'','password':'','confirm_password':''};


const schema = yup.object({
    oldpassword: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères'),
    password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères'),
    confirm_password: yup.string().required('Le mot de passe est requis').min(6, 'Le mot de passe doit faire au moin 6 caractères').oneOf([yup.ref('password'), null], "La confirmation du mot de passe doit être identique au mot de passe"),
    
});

const formValues = reactive({
  oldpassword: "",
  password: "",
  confirm_password: "",  
 
});
 
const code =  localStorage.getItem('code');
const token =  localStorage.getItem('login_token');

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;
          // alert(errorsBis[field])
        });
        errors.value=''
}

const update_password = async () => {
  
  try {  
    
   
    btnLoad.value=false;
    schema.validateSync(formValues);
    
    axios.post(backApi+'/secure/users/change-password', {
      code:code,
      oldPassword: formValues.oldpassword,
      newPassword: formValues.password,
      checkPassword: formValues.confirm_password      
    }, {
    headers: {          
      'Content-Type': 'application/json', 
      Authorization: `Bearer ${token}`          
    }
  }).then((response) => {
    btnLoad.value=true; 
    if(response.status === 200){
      success.value=response.data.msg         
                
    }

  }).catch((error) => {
    btnLoad.value=true; 
      if( error.response){  
          errorId.value=error.response.data.errorId;            
          errors.value=error.response.data.message;  
          if(error.response.status === 401){                          
                  router.push('/');
          }              
      }
  });
    

  } catch (error) {
    console.error(error);
    
     errors.value="une erreur inattendue est apparue"
  
  }
  

};
</script>