<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
       

        
      </div>
      <div class="d-flex align-items-center">

           
            <button
              class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#companyModal"
              style="background-color: #EB9355;"
              @click="initField"
            >
              Ajouter un providor
              <i class="flaticon-plus position-relative ms-5 fs-12"></i>
            </button>


      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
              Code 
            </th> 
             
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Nom
              </th> 
             
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Description
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Type
              </th>
                         
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr   v-for="(providor) in providors" :key="providor.code">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ providor.code }}
              </td> 
                          
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ providor.name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ providor.description }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">                
                {{ providor.type}}
              </td>
                          
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">

                    
                   
                    <!-- <li> 
                      <a
                        class="dropdown-item d-flex align-items-center"  style="color: red;"
                        href="javascript:void(0);"
                         data-bs-toggle="modal"
                        data-bs-target="#filterLeadModal"
                        @click="showScreen(providor.code)"                         
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        <span   class="">Supprimer</span>
                        
                      </a>
                    </li>  -->
                    
                    <li  >
                      <a
                        class="dropdown-item d-flex align-items-center"  style=""
                        href="javascript:void(0);"                        
                        data-bs-toggle="modal"
                        data-bs-target="#companyModal"
                        @click="showProvidor(providor.code)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>                       
                        <span   class="">Modifier</span>                        
                      </a>
                    </li>                           
                  </ul>
                </div>
              </td>
            </tr>
                      
          </tbody>
        </table>
      </div>
      <div
      v-if="total>0" class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
      >
        <p v-if="total>10" class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ page*10 }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <p v-if="total<=10"  class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ total }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0" class="page-item">
              <a class="page-link" @click="getProvidors(page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="getProvidors(numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"  class="page-item">
              <a class="page-link"  @click="getProvidors(page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

  <div class="modal fade" id="filterLeadModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-sm modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1  class="modal-title fs-5">Alert</h1>                
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="cancel()"></button>
            </div>
            <div class="modal-body">
                      <div style="">
                        <h6  v-if="errors==''" class="text-red fw-bold mb-0 text-center" style="color: red;">{{ 'Voulez-vous vraiment supprimer ce contact'}}</h6>

                                                
                      </div>
                      <div v-if="errors!==''">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">code: {{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>              
                        
                      </div>
                     
            </div>
            <div class="modal-footer">                
                <button type="button" class="btn default-outline-btn bg-transparent " style="background-color: #FFF;"  data-bs-dismiss="modal" @click="cancel()">
                    Non
                </button>               
                <button  :disabled="!btnLoad" type="button" class="btn" style="background-color: #EB9355;" @click="deleteBusiness()">           
                  <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                  <span style="color: #FFF;">Oui</span> 
                </button>
            </div>
        </div>
    </div>
</div>
   
  <div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="save">
            <div class="modal-header">
                <h1 class="modal-title fs-5">{{edit==0?'Ajouter un providor':'Modifier un providor'}}</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">                    
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">                            
                            
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Nom du providor
                                </label>              
                                <div >  
                                 
                                        <input 
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                          placeholder="ex: I-MONEY"
                                          v-model="formValues.name"                                    
                                          :class="{ 'is-invalid': errorsBis['name'] }"
                                          id="name"                                                                               
                                          @blur="validate('name')"  
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['name']}}</span> 
                                </div>

                            </div>
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Description
                                </label>              
                                <div >  
                                 
                                        <input 
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"                                         
                                          placeholder="ex: saisissez la description ici"
                                          v-model="formValues.description"                                   
                                          :class="{ 'is-invalid': errorsBis['description'] }"
                                          id="description"                                                                                 
                                          @blur="validate('description')"  
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['description']}}</span> 
                                </div>

                            </div>

                          </div>
                        
                    </div>
            </div>
            <div class="modal-footer">                
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  {{edit==0?'Enregister':'Modifier'}}
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
  

<div id="snackbar"></div>
</template>

<script setup  lang="ts">
import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import router from '../../router/index'
import * as yup from 'yup';
import {Providor } from "@/utils/Model";

import {current_permissions} from '../../utils/permission';
import {showToast,checkSessionValidate} from "@/utils/function";

const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);
const btnLoad = ref(true);

const selectedValidation = ref('N');
const selected = ref(false);


const loader = ref(false);

let token: string|null = localStorage.getItem('login_token');
let codeOrganization: string|null = localStorage.getItem('organization_code');
let codeUser: string|null = localStorage.getItem('code');
let role: string|null = localStorage.getItem('role');

const providors =ref(new Array<Providor>());
// const businessItem =ref({} as Reloading);
let edit = 0;
let itemCode = '';
let selectedOrganization = '';





  let data = [];
 
  const errors = ref('');  
  const errorId = ref('');  
  const success = ref('');
  const errorsBis = {'name':'','description':''};
  const pageLoad = ref(false);

  const formValues = reactive({    
    name: "",  
    description: ""
  });

  
  const schema = yup.object({    
      name: yup.string().required('Le nom est requis'),  
      description: yup.string().required('La description est requise'),  
      
  });

  

function handleValidationChange(event) {
  selectedValidation.value = event.target.value;
}


const initField = () =>{
  formValues.name=  '';  
  formValues.description= ''; 
  // formValues.maxAmount= 0;
  // selectedValidation.value = 'N';
  // selected.value=false;
  edit=0;
}


const getProvidors = (arg=1) =>{
    // +arg
   
    axios.get(backApi+'/secure/providers/',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        providors.value = response.data;  //.data.business            
        data  = response.data;  
        // total.value=parseInt(response.data.pagination.total);
        // page.value=parseInt(response.data.pagination.page);
        // pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        // currentPage.value=parseInt(response.data.pagination.currentPage);
        // numPages.value=parseInt(response.data.pagination.numPages);

    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const showProvidor = (code) =>{
    // +arg
     axios.get(backApi+'/secure/providers/'+code+'/show',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        formValues.name=  response.data.name;  
        formValues.description=  response.data.description; 
        // formValues.maxAmount=  response.data.maxPayoutAmount;         
        // selectedValidation.value=(response.data.validatePayoutBeforProcess)?'O':'N';
        // selected.value = response.data.validatePayoutBeforProcess;
        itemCode = response.data.code;
        

        edit=1;        
        // console.log(response.data);
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                console.log(error.response);
              }   
              
          }
  });
}



const showScreen = (code)=>{ 
  itemCode=code;
  
}

const cancel = () =>{
  itemCode="";
  errorId.value="";
  errors.value="";
}

const save =()=>{
  if (edit==0){
    storeProvidor()
  }else{
    updateProvidor()
    
  }
  
}


const storeProvidor =()=>{
  btnLoad.value=false; 
try {  
  schema.validateSync(formValues);
   
  axios.post(`${backApi}/secure/providers/new`,{          
          name:formValues.name,
          description:formValues.description,
          // validatePayoutBeforProcess:(selectedValidation.value=='N')?false:true,
          // maxPayoutAmount:formValues.maxAmount,
          // organization:codeOrganization, 
          
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        btnLoad.value=true;
        if(response.status === 201){ 
          console.log(response);          
          showToast("Enregistrement effectué avec succès","green");
          router.go();  
        }      
        
    }).catch((error) => {
      btnLoad.value=true;
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         btnLoad.value=true;
              
}

}

const updateProvidor =()=>{
  btnLoad.value=false;
try {  
  schema.validateSync(formValues);
  // alert(formValues)
  axios.put(`${backApi}/secure/providers/${itemCode}/edit`,{          
          name:formValues.name,
          description:formValues.description,
          // validatePayoutBeforProcess:(selectedValidation.value=='N')?false:true,
          // maxPayoutAmount:formValues.maxAmount,
          code:itemCode,     
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {            
        btnLoad.value=true;
        if(response.status === 200){   
          showToast("Modification effectuée avec succès","green");            
          router.go();  
        }      
        
    }).catch((error) => {
      btnLoad.value=true;
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         btnLoad.value=true;       
              
}

}

const deleteBusiness =()=>{
  btnLoad.value=false;
try {   
  // alert(itemCode);
  const headers = {
  'Authorization':  `Bearer ${token}`
  }
const data = {
  code: itemCode
}
  axios.delete(`${backApi}/secure/business/delete`, {headers, data})
  .then(response => {
    if(response.status === 200){ 
      btnLoad.value=true;
          console.log(response);          
          router.go();  
        }   
})
.catch(error => {
  if( error.response){
      
      if(error.response.status === 401){
        router.push('/')
        
      }else{
        errorId.value=error.response.data.errorId;
        errors.value=error.response.data.message;
      } 
      
  }
});

} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}

const exportExcel = () =>{
  
  import("../../utils/Export2Excel").then((excel)=>{
    // const data = transactions.value;
    const Header =["CODE RECHARGEMENT","DESCRIPTION","MONTANT","DATE","STATUT"];
    const Fields = ["code","description","amount","createdAt","status"];//mnoName
    // console.log(data);
    const dataForExcel= formatJSon(Fields,data);
    // console.log(dataForExcel);
    excel.export_json_to_excel({     
      header:Header,
      data:dataForExcel,
      sheetName:"Feuil1",
      filename:"rechargement",      
      autoWidth: true,
      bookType:"xlsx",
  });
  });
}

function formatJSon(FilterData,JsonData){
  return JsonData.map((v)=>FilterData.map(j=>{
      return v[j];
    }));
}


 onMounted(async () => { 
  checkSessionValidate(1);
  getProvidors();
   
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)
 
});
</script>