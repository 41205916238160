<template>
  <div v-if="errors!==''" style="margin-top: 5%;">
        <h6 v-if="errorId!==''" class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
      </div>
      <div v-if="success!==''" style="margin-top: 5%;">
        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
  </div>
  <div class="clearfix"  v-if="!loader">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="loader" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center" style="border-style:solid 1px red ;">   
        <!-- <h6>Pays</h6>     -->
        <select  name="business" id="business" class="form-select fs-md-15 text-black shadow-none" @change="handleBusinessChange" >
            <option value="">Selectionnez une catégorie</option>                 
            <option v-for="businessItem in business" :value="businessItem.code" :key="businessItem.code"> {{ businessItem.name }}</option>
        </select>
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        <select  name="sub_business" id="sub_business" class="form-select fs-md-15 text-black shadow-none" @change="handleSubBusinessChange" >
            <option value="">Selectionnez une sous-catégorie</option>                 
            <option v-for="subBusinessItem in subBusiness" :value="subBusinessItem.code" :key="subBusinessItem.code"> {{ subBusinessItem.name }}</option>
        </select>
       &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
       <select  name="country" id="country" class="form-select fs-md-15 text-black shadow-none" @change="handleCountryChange" required>
          <option value="">Selectionnez le pays </option>                 
          <option  value="SN" >Sénégal</option>
          <option  value="CI" > Côte d'Ivoire</option>
       </select>
       &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
       <!-- <h6>Date de transfert</h6>  -->
       <input
                  v-model="today"
                  type="date"
                  class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                  id="lead-calendar"
                  :placeholder="leadPlaceholder"
                 
                />
                <button
                class="default-outline-btn bg-transparent position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
                type="button"
                style="background-color: #EB9355;visibility: hidden;" 
                data-bs-toggle="modal"
                data-bs-target="#filterLeadModal"     
                id="toastElement"
                
                  >  
                  
                <i class="flaticon-close position-relative ms-5 top-2 fs-15"></i>
          </button>         
       <!-- <select  name="operator" id="operator" class="form-select fs-md-15 text-black shadow-none" @change="handleOperatorChange" required>
          <option value="">Selectionnez un opérateur</option>                 
          <option v-for="operator in operators" :value="operator.mnoName" :key="operator.code"> {{ operator.mnoName }}</option>
      </select> -->
      </div>
      <div class="d-sm-flex align-items-center">
          <input id="fileUpload" type="file" @change="handleImageUpload">
          
        
           <button
                class="default-outline-btn bg-transparent position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
                type="button"
                @click="getModelExcel"          
                  >       
                Télécharger le fichier modèle 
                <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button 
                class="default-btn d-block  border-0 position-relative  transition fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
                type="button"                 
                data-bs-toggle="modal"
                data-bs-target="#contact"   
                @click="getContacts"      
                  >       
                Importer contact 
                <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          </button>
          
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table id="" class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                NOM & PRENOM(S) DU BENEFICIAIRE
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              PRENOM(S)
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TELEPHONE
              </th>
               <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                MONTANT
              </th> 
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                OPERATEUR
              </th>               
            </tr>
          </thead>
          <tbody>

            <tr  v-for="(transfert) in transferts" :key="transfert.mobile">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <div class="d-flex align-items-center">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                    />
                  </div>
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                   
                    {{ transfert.clientId}}
                  </div>
                </div>
              </th>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transfert.clientLastName}}
              </td> -->
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transfert.mobile}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transfert.amount.toLocaleString()}}
              </td>
               <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ transfert.operator}}
              </td> 
              <!-- <td class="shadow-none lh-1 fw-medium">
                {{ transfert.country}}                
              </td> -->
             
            </tr>
           
          </tbody>
        </table> 
        
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
        <p  class="mb-0 text-paragraph">
          <!-- Showing <span class="fw-bold">{{ page*10 }}</span> out of-->
          Nombre total de bénéficiaire : <span class="fw-bold">{{ total }}</span>  | Montant Total :<span class="fw-bold">{{ totalTransfertAmount.toLocaleString() }}</span> XOF
        </p>
        <nav    class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li    class="page-item">
              <!-- <a class="page-link"  aria-label="Previous"> -->
              <!-- Montant Total :<span class="fw-bold">{{ totalTransfertAmount.toLocaleString() }}</span> XOF -->
              <!-- </a> -->
            </li>
           
            <li   class="page-item">
              <button
                  type="button"
                  class="default-outline-btn bg-transparent fw-medium rounded-1 pt-12 pb-12 ps-25 pe-25"                 
                  @click="cancel" 
                >
                   Rafraichir
              </button>
            </li>
            <li   class="page-item">
              <!-- :disabled="" -->
              <button
                  
                  v-if="totalChecking==0"
                  type="button"
                  class="d-block text-center w-100 border-0 box-shadow fw-medium text-white rounded-1 pt-12 pb-12 ps-25 pe-25"
                  :disabled="totalChecking>0 || !btnLoad"
                  style="background-color: #EB9355;" 
                  @click="sendMoney" 
                >
                <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                   Transferer l'argent
              </button>
            </li>
          </ul>
        </nav>

       

        
      </div>
    </div>
  </div>

  
  <div class="modal fade" id="contact" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                
                <h1 class="modal-title fs-5">Importation des contacts client</h1>
                <button type="button" id="close" @click="close"  class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">code: {{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>              
                        
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row" v-if="current_permissions('ADMIN-USER')">                          
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Pays
                                  </label>              
                                  <select  name="organization" id="organization" class="form-select fs-md-15 text-black shadow-none" @change="handleCountryChangeContact" required>
                                      <option value="">Selectionnez...</option>                 
                                      <option  value="SN" >Sénégal</option>
                                      <option  value="CI" > Côte d'Ivoire</option>
                                  </select>
                              </div>
                              
                          </div>
                         <br/>
                          
                        <div class="row">
                          <table class="table text-nowrap align-middle mb-0">
                                <thead>
                                  <tr>
                                    
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                                    >
                                    # 
                                  </th> 
                                  
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                                    >
                                      Nom complet
                                    </th>           
                                    
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                                    >
                                      numéro de téléphone
                                    </th>
                                  
                                    
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                                    >
                                    Opérateur
                                  </th>
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                                    >
                                    Montant
                                  </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr   v-for="(reloading) in contacts" :key="reloading.code">
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                      <!-- {{ reloading.code }} $event.target.value,$event.target-->
                                        <input
                                          
                                          name="beneficiary"
                                          class="form-check-input"
                                          type="checkbox"
                                          :value="reloading.code+':'+reloading.first_name+' '+reloading.last_name+'-'+reloading.contact"
                                          :id="reloading.code"   
                                          @click="handleCheckedContact"                                   
                                        /> 
                                    </td> 
                                              
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                      {{ reloading.first_name+" "+reloading.last_name }}
                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis">                
                                      {{ reloading.contact}}
                                    </td>          
                                    <td
                                      class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                                    >
                                      <select  name="operator" :id="reloading.code+':operator'" class="form-select fs-md-15 text-black shadow-none" @change="handleOperatorChangeContact" required>
                                        <option value="">Selectionnez...</option>                 
                                        <option v-for="operator in operators" :value="reloading.code+':'+operator.mnoName" :key="operator.code"> {{ operator.mnoName }}</option>
                                      </select>
                                    </td>
                                    <td
                                      class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                                    >
                                    <input                                          
                                          
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          type="number"
                                          value=""
                                          :id="reloading.code+':amount'"   
                                          @keyup="handleAmountChangeContact($event.target.value,$event.target)" 
                                           
                                        /> 
                                  </td>
                                  </tr>
                                            
                                </tbody>
                              </table>
                              <div v-if="total>0" class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center">
                                      <p v-if="total>10" class="mb-0 text-paragraph">
                                        Showing <span class="fw-bold">{{ page*10 }}</span> out of
                                        <span class="fw-bold">{{ total }}</span> resultats
                                      </p>
                                      <p v-if="total<=10"  class="mb-0 text-paragraph">
                                        Showing <span class="fw-bold">{{ total }}</span> out of
                                        <span class="fw-bold">{{ total }}</span> resultats
                                      </p>
                                      <nav class="mt-15 mt-md-0">
                                        <ul class="pagination mb-0">
                                          <li  v-if="(page-1)>0" class="page-item">
                                            <a class="page-link" @click="getContacts(page-1)" aria-label="Previous">
                                              <i class="flaticon-chevron-1"></i>
                                            </a>
                                          </li>
                                        
                                          <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
                                            <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
                                            <a v-else class="page-link" @click="getContacts(numPage)">{{ numPage}}</a>
                                          </li>
                                        
                                          <li v-if="(page+1)<=numPages"  class="page-item">
                                            <a class="page-link"  @click="getContacts(page+1)" aria-label="Next">
                                              <i class="flaticon-chevron"></i>
                                            </a>
                                          </li>
                                        </ul>
                                      </nav>
                                    </div>
                        </div>
                        
                    </div>
            </div>
            <div class="modal-footer">                
               
                <button   :disabled="!btnLoad" type="button" class="btn" style="background-color: #EB9355;" @click="addBeneficiarydata()">           
                  <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                  <span style="color: #FFF;">Importer</span> 
                </button>
                
            </div>
        </div>
    </div>
</div>

  <div class="modal fade" id="filterLeadModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1  class="modal-title fs-5">Notification</h1>                
                <button type="button" id="close-fix-modal" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div class="modal-body">
                      <div style="margin-left: 15%" class="">
                        <!-- style="margin-left: 30%;" -->
                        <h6 style="width: 90%;"   :class=" (amountResult==0)?'text-success fw-bold mb-0 ': 'text-red fw-bold mb-0 ' " >Erreur sur le montant défini :<span   style="float:right;"> {{ amountResult }}</span> </h6>
                        <h6 style="width: 90%;"   :class=" (telSizeResult==0)?'text-success fw-bold mb-0 ': 'text-red fw-bold mb-0 ' " >Erreur sur la taille d'un numéro de téléphone :<span style="float:right;" >  {{ telSizeResult }}</span></h6>
                        <h6 style="width: 90%;"   :class=" (telFormatResult==0)?'text-success fw-bold mb-0 ': 'text-red fw-bold mb-0 ' " >Erreur sur le format d'un numéro de téléphone :<span style="float:right;" > {{ telFormatResult }}</span></h6>
                        <h6 style="width: 90%;"   :class=" (telOperatorResult==0)?'text-success fw-bold mb-0 ': 'text-red fw-bold mb-0 ' " >Erreur sur le nom d'un opérateur :<span style="float:right;" > {{ telOperatorResult }}</span></h6>
                        <h6 style="width: 90%;"   :class=" (duplicateTelResult==0)?'text-success fw-bold mb-0 ': 'text-red fw-bold mb-0 ' " >Numéro de téléphone en doublon :<span style="float:right;" > {{ duplicateTelResult }}</span></h6>
                                                                      
                      </div>
                      <hr/>
                      <div style="margin-left: 15%" class="">                        
                        <h6 v-if="totalChecking==0" class="fw-bold mb-0" >Vous pouvez procéder au transfert. </h6>         
                        <h6 v-if="totalChecking>0" style="color:red"   class="text-red fw-bold mb-0" >Corrigez ces erreurs avant de continuer.</h6>
                      </div>
                      <div class="row" v-if="totalChecking>0">
                          <table class="table text-nowrap align-middle mb-0">
                                <thead>
                                  <tr>
                                   
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                                    >
                                      Nom complet
                                    </th>           
                                    
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                                    >
                                      numéro de téléphone
                                    </th>
                                  
                                    
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                                    >
                                    Opérateur
                                  </th>
                                    <th
                                      scope="col"
                                      class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
                                    >
                                    Montant
                                  </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr   v-for="(incorrect_totalItem) in incorrect_total" :key="incorrect_totalItem.code">
                                   <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                                      {{ incorrect_totalItem.clientId }}
                                    </td>
                                    <td class="shadow-none lh-1 fw-medium text-black-emphasis">                
                                       <input                                          
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          type="text"
                                          :value="incorrect_totalItem.mobile"
                                          :id="incorrect_totalItem.code+':mobile'"   
                                          @keyup="handleTelFixed($event.target.value,$event.target)" 
                                           readonly
                                        /> 
                                    </td>          
                                    <td
                                      class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                                    >
                                         <!-- <input                                          
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                            type="text"
                                            :value="incorrect_totalItem.operator"
                                            :id="incorrect_totalItem.code+':operator'"   
                                            @keyup="handleAmountChangeContact($event.target.value,$event.target)" 
                                            
                                          />  -->
                                      <select  name="operator" :id="incorrect_totalItem.code+':operator'" class="form-select fs-md-15 text-black shadow-none" @change="handleOperatorFixed" disabled="true">
                                          <!-- <option value="">Selectionnez...</option>  -->
                                          <option :value="incorrect_totalItem.code+':'+incorrect_totalItem.operator">{{ incorrect_totalItem.operator }}</option>                 
                                          <option v-for="operator in operators" :value="incorrect_totalItem.code+':'+operator.mnoName" :key="operator.code"> {{ operator.mnoName }}</option>
                                      </select>
                                    </td>
                                    <td
                                      class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
                                    >
                                    <input                                          
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                            type="text"
                                            :value="incorrect_totalItem.amount"
                                            :id="incorrect_totalItem.code+':amount'"   
                                            @keyup="handleAmountFixed($event.target.value,$event.target)" 
                                            readonly
                                          /> 
                                  </td>
                                  </tr>
                                            
                                </tbody>
                              </table>
                            </div>
            </div>
            <div class="modal-footer "  v-if="totalChecking>0">  
                  <button   :disabled="!btnLoad" type="button" class="btn" style="background-color: #EB9355;" @click="addBeneficiaryfixed()">           
                  <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                  <span style="color: #FFF;">Corriger</span> 
                </button>           
              
            </div>
        </div>
    </div>
</div>
<div id="snackbar"></div>
</template>

<script setup  lang="ts">
import {ref, onMounted,onBeforeMount,reactive,inject} from "vue";
import * as yup from 'yup';
import axios from 'axios';
import flatpickr from "flatpickr";
import html2pdf from "html2pdf.js";
import LeadscontentPdf from "./LeadsContentPdf.vue";
import router from '../../../router/index'
import conf from '../../../../env.json'
import {current_permissions} from '../../../utils/permission';
import { Business, Contact } from "@/utils/Model";
import {showToast,checkAmount,getBeneficiaryWithIncorrectAmount,checkTelSize,getBeneficiaryWithIncorrectTelSize,checkDuplicateTel,checkTelFormat,getBeneficiaryWithIncorrectTelFormat,checkTelOperatorName,getBeneficiaryWithIncorrectTelOperatorName,checkSessionValidate,strUUID,checkFormat} from '../../../utils/function';
import Countries from '../../../utils/Countries'
import { read, utils } from 'xlsx';
const backApi=inject('back');

let amountResult = 0;
let telSizeResult = 0;
let telFormatResult=0;
let telOperatorResult = 0;
let duplicateTelResult = 0;
let incorrect_amount =  [];//new Object();
let incorrect_telSize =    [];//new Object();
let incorrect_telFormat =   [];//new Object();
let incorrect_telOperator =   [];//new Object();
let incorrect_total =   [];//new Object();
let phoneMask =0;
let totalChecking = 0;
let codeOrganization: string|null = localStorage.getItem('organization_code');
let beneficiary =  new Object();
let beneficiary_amount =  new Object();
let beneficiary_operator = new Object();
declare interface Transaction {
    id: number,
    checkoutId: string,   
    operator: string,
    contact: string,
    amount: string,    
    transactionDate: string,
    paymentStatus: string
  }

  declare interface Organization {
  id : string,
  code: string,
  name: string,
  description: string,    
  email: string,
  created_at: string
  }

  declare interface Operator {
  // id : string,
  // code: string,
  mnoName: string,
  status: string,    
  countryCode: string  
  }
const btnLoad = ref(true);
const leadPlaceholder = "Select date";
const errors = ref('');  
const errorId = ref('');  
const success = ref('');
const errorsBis = {'contact':'','amount':''};
const formValues = reactive({    
    contact: "",
    amount:""
  });
const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object({     
     contact: yup.string().required('Le numéro de téléphone est requis'),//.matches(phoneRegExp,"Veuillez saisir un numéro de telephone valide").test('len', "Le numero de téléphone doit faire exactement 10 chiffres", val => val.length === 10),     
     amount: yup.number().required('Le montant est requis'),
  });
let data = [];
let beginDate = ref('');
let endDate = ref('');
let today = ref('');
const selectedOrganization = ref('');
const selectedOperator = ref('');
const selectedStatus = ref('');
const selectedCountry = ref('');
const selectedBusiness = ref(null);
const loader = ref(false);
const page = ref(1);
const total = ref(0);
//const pageServ = ref('');
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);
const gettran = ref(false);
const search = ref(false);
const search1 = ref(false);
const amount = ref(0);
const operator = ref('');
const paymentStatus = ref(''); 
let importArray = [];
const transferts = ref([]);
let totalAmount = 0;
let totalAmount1 = 0;
const totalTransfertAmount = ref(0);
let transactionsJson = [];
let transactionsJson1 = [];
// const fileUpload = ref('');

    
const transactions =ref(new Array<Transaction>());
const organizations =ref(new Array<Organization>());
// const operators =ref(new Array<Operator>());
let operators =ref(new Array<Operator>());
let operatorsTemp =new Array<Operator>();
const transaction =ref({} as Transaction);
const business =ref(new Array<Business>());
const subBusiness =ref(new Array<Business>());
let businessTemp =new Array<Business>();
const contacts =ref(new Array<Contact>());
let role: string|null = localStorage.getItem('role');


  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}

function formatDateEn(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}

function formatDateEnBis(value,d=0){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate()+d;  
        day = day<10?'0'+day:day;      
        return year+'-'+month+'-'+day;
        
    }
}
function handleBusinessChange(event) {
      selectedBusiness.value = event.target.value;
      subBusiness.value = [];
      var j = 0;
      var businessArr = new Array<Business>();
      while(j<businessTemp.length){    
          if(businessTemp[j].parent?.code == event.target.value){         
            businessArr.push(businessTemp[j]);
          }      
          j++;
      }

      if(businessArr.length>0){
        subBusiness.value = businessArr;
        document.getElementById("sub_business").style.display='block'; 
        
      }else{
        document.getElementById("sub_business").style.display='none'; 
      }      
      
}

function handleSubBusinessChange(event) {
      selectedBusiness.value = event.target.value;      
}

function handleOrganizationChange(event) {
      selectedOrganization.value = event.target.value;
    }

function handleOperatorChange(event) {
  selectedOperator.value = event.target.value;
  errors.value='';
  success.value='';
  errorId.value='';
}

function handleStatusChange(event) {
  selectedStatus.value = event.target.value;
}

function handleCheckedContact(event) {
  let contactTemp = event.target.value.split(":");  
    if(beneficiary[contactTemp[0]]){ 
      delete beneficiary[contactTemp[0]];
      delete beneficiary_operator[contactTemp[0]];
      delete beneficiary_amount[contactTemp[0]];
      document.getElementById(contactTemp[0]+":amount").value='';
      document.getElementById(contactTemp[0]+":operator").value='';
      // alert(document.getElementById(""+contactTemp[0]).value);
      
  }else{    
    beneficiary[contactTemp[0]]=contactTemp[1];
  } 
  errorId.value=''
  errors.value=''
  // console.log(beneficiary) 
  // console.log(beneficiary_operator)
  // console.log(beneficiary_amount)   
}

function handleOperatorChangeContact(event) {
  let operatorTemp = event.target.value.split(":");
  beneficiary_operator[operatorTemp[0]]=operatorTemp[1];
  // console.log(beneficiary)
  errorId.value=''
  errors.value=''
  document.getElementById(operatorTemp[0]+":operator").style.borderColor=''; 
}

function handleAmountChangeContact(myvalue,tag){
  let amountTemp = tag.id.split(":");
  beneficiary_amount[amountTemp[0]]=myvalue;
  // console.log(beneficiary_amount)
  errorId.value=''
  errors.value=''
  document.getElementById(amountTemp[0]+":amount").style.borderColor=''; 
}

function addBeneficiarydata(){
  btnLoad.value=false;
  if(Object.values(beneficiary).length>0){
    for (const property in beneficiary) {
      let contactTemp = beneficiary[property].split("-"); 
      if(beneficiary_amount[property] && beneficiary_operator[property]){ 
      const user = {
                    clientId: contactTemp[0],                 
                    mobile: contactTemp[1],
                    amount: beneficiary_amount[property],
                    operator: beneficiary_operator[property],
                    countryCode:selectedCountry.value                             
                  };      
      
      transactionsJson1.push(user);    
      totalAmount1+= parseInt(beneficiary_amount[property]);
      }else{
        transactionsJson1=[]; 
        if(!beneficiary_amount[property]){ 
          document.getElementById(property+":amount").style.borderColor='red';          
        }
        if(!beneficiary_operator[property]){ 
          document.getElementById(property+":operator").style.borderColor='red';          
        }
        errorId.value = strUUID();
        errors.value='Remplissez les champs manquant du formulaire s\'il vous plait';
   
      }
    }
    if(errorId.value==""){
      const transfertTemp = transferts.value;
      transferts.value = transfertTemp.concat(transactionsJson1);  //transferts.value =  transactionsJson1  
      totalTransfertAmount.value += totalAmount1;
      showToast("Import effectué avec succès","green");
      document.getElementById("close").click();
    }
  }
  btnLoad.value=true;
}

function handleCountryChangeContact(event) {
  selectedCountry.value = event.target.value;
  var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == event.target.value){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
  // alert(operatorArr[0].phoneMask);
  phoneMask = operatorArr[0].phoneMask;
  operators.value = operatorArr;
}

function close() {
  beneficiary =  new Object();
  beneficiary_amount =  new Object();
  beneficiary_operator = new Object();  
  // getContacts();
  
}

function handleCountryChange(event) {
   selectedCountry.value = event.target.value; 
  transferts.value = [];
  operators.value = [];
  importArray=[];
  transactionsJson=[];
  totalTransfertAmount.value = 0;
  totalAmount = 0;
  amountResult = 0;
  telSizeResult = 0; 
  telFormatResult=0;
  telOperatorResult = 0;
  duplicateTelResult = 0;
  totalChecking = 0;
  incorrect_amount =    [];//new Object();
  incorrect_telSize =    [];//new Object();
  incorrect_telFormat =   [];//new Object();
  incorrect_telOperator =   [];//new Object();
  incorrect_total =   [];//new Object();
  // let fileUpload = 
  document.getElementById("fileUpload").value="";
  //  fileUpload.val
  total.value=0;
   errors.value='';
   success.value='';
   errorId.value='';

   var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == event.target.value){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
  // alert(operatorArr[0].phoneMask);
  phoneMask = operatorArr[0].phoneMask;
  operators.value = operatorArr;
  console.log(operators.value);
}

function handleCountryChangeOLD(event) {
   selectedCountry.value = event.target.value;
  // operators
  var j = 0;
  var operatorArr = new Array<Operator>();
  while(j<operatorsTemp.length){    
      if(operatorsTemp[j].countryCode == event.target.value){         
          operatorArr.push(operatorsTemp[j]);
      }      
      j++;
  }
   operators.value = operatorArr;
   errors.value='';
   success.value='';
   errorId.value='';
}

function handleAmountChange(event) {    
      let withdrawAmount = event.target.value;
      if(parseFloat(withdrawAmount)<=0){
        formValues.amount=''
      }
}

function getModelExcel() {       
        window.open(`/model_payout.xls`, "_blank");
}
function cancel(){
  router.go();
}

const getContacts = (arg=1) =>{
    // +arg+param
    let param = '';
    param = (role=='SUPERADMIN')?'':`?organization=${codeOrganization}`
    axios.get(backApi+'/secure/contactbook/',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {  
      contacts.value = [];      
      contacts.value = response.data;  //.data.contacts            
                
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}
  
onMounted(() => {
  checkSessionValidate(1);
   getOrganizations();
  //  getContacts();
  getOperators();
  getBusiness();
  loader.value=true; 
  setTimeout(function () {
    //loader.value=true; 
        flatpickr("#lead-calendar", {
        altInput: true,
        altFormat: "F j, Y",
        dateFormat: "Y-m-d",
        defaultDate: "today",
        enableTime: true,
      });
        },1000);

 

  flatpickr("#basic-calendar", {
    dateFormat: "Y-m-d H:i",
  });

});
const token =  localStorage.getItem('login_token');
const code =  localStorage.getItem('organization_code');


const getBusiness = () =>{
    // +arg
    let param = '';
    param = (role=='SUPERADMIN')?'':`?organization=${code}`
    axios.get(backApi+'/secure/business/'+param,{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        // business.value = response.data;
        businessTemp = response.data;
        getBusinessParent(businessTemp)
                   
        

    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const getBusinessParent = (businessParents) =>{
  var j = 0;
  var businessArr = new Array<Business>();
  while(j<businessParents.length){    
      if(businessParents[j].parent == null){         
        businessArr.push(businessParents[j]);
      }      
      j++;
  }
  business.value = businessArr;
}

const getOrganizations = () =>{

    axios.get(backApi+'/secure/organizations/',{  
      headers: {
           
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {
        // users.value = response.data;
        organizations.value = response.data;  //.data.users          
        console.log(response.data);
        
        
    }).catch((error) => {
          if( error.response){
              console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                //window.location.href = '/';
                //router.push('/');
              }   
              
          }
   });
  //  loader.value=true;
}
const getOperators = () =>{

axios.get(backApi+'/public/operators/',{  //secure
  headers: {
       
        Authorization: `Bearer ${token}`
  } 
})
.then((response) => {
    // users.value = response.data;
    operatorsTemp = response.data;  //.data.users          
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            
          }   
          
      }
});
//  loader.value=true;
}




//[top, left, bottom, right]


const sendMoney =()=>{

if(selectedCountry.value!=='' && (transactionsJson.length>0 || transactionsJson1.length>0)){// && selectedOperator.value!==''
 btnLoad.value=false;
      if(today.value===''){
        today.value =  formatDateEn(new Date()); 
      }
      
    const todayE =  formatDateEn(new Date(today.value));   //,1
    
try {  
  // alert(selectedOperator.value);
  let transactionsJson3 = transactionsJson.concat(transactionsJson1);
  axios.post(`${backApi}/secure/transactions/init`,{    
    /// organization:code,    
    payoutType:2, 
    operationType:parseInt(process.env.VUE_APP_PAYOUT_OPTTYPE),  
    scheduledAt:todayE, 
    business:selectedBusiness.value, 
    ///countryCode:selectedCountry.value,
    ///operator: selectedOperator.value,
    // transactions: transactionsJson,
    transactions: transactionsJson3
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => { 
        btnLoad.value=true; 
        selectedCountry.value ='';
        selectedOperator.value ='';
        if(response.status === 200 || response.status === 201){      
          showToast("Transfert réussi","green");
          // router.go();
          setTimeout(() => {
            router.go();
            }, 4000);   
          // success.value='Transfert réussi'; 
         
          //console.log(response);
        }      
        
    }).catch((error) => {
      btnLoad.value=true; 
      if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
  btnLoad.value=true; 
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}
}else{
  
  if(transactionsJson.length<=0){
    errors.value="Veuillez importer un fichier"
  }
  if(selectedCountry.value==='' ){
    errors.value="Sélectionnez le pays s'il vous plaît"
  }
}

}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value='';
        success.value='';
}

const handleImageUpload = (event) => {
  loader.value=false;
  const file = event.target.files[0];
      const reader = new FileReader();
      transferts.value = [];
      //operators= [];
      importArray=[];
      transactionsJson=[];
      totalTransfertAmount.value = 0;
      totalAmount = 0;
      total.value=0;
      errors.value='';
      success.value='';
      errorId.value='';
      amountResult = 0;
      telSizeResult = 0;
      telFormatResult=0;    
      telOperatorResult = 0;  
      duplicateTelResult = 0;
      totalChecking = 0;
      incorrect_amount =    [];//new Object();
      incorrect_telSize =    [];//new Object();
      incorrect_telFormat =   [];//new Object();
      incorrect_telOperator =   [];//new Object();
      incorrect_total =   [];//new Object();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

      
        var i = 0;
       
        for (const row of jsonData) {
          //(row[0]!==undefined && row[0]!=='') && (row[1]!==undefined && row[1]!=='') && (row[2]!==undefined && row[2]!=='') && (row[3]!==undefined && row[3]!=='') && (row[4]!==undefined && row[4]!=='')
          if((row[0]!==undefined && row[0]!=='NOM') && (row[1]!==undefined && row[1]!=='PRENOM') && (row[2]!==undefined && row[2]!=='PHONE_NUMBER') && (row[3]!==undefined && row[3]!=='AMOUNT')  && (row[4]!==undefined && row[4]!=='PAYMENT_METHOD')){// && (row[4]!==undefined && row[4]!=='PAYMENT_METHOD')
              // if((row[2].length==10||row[2].length==8) && parseInt(row[3])>0){
                const user = {
                  clientId: row[0]+" "+row[1],                 
                  mobile: row[2],
                  amount: row[3],
                  operator: row[4],
                  countryCode:selectedCountry.value,
                  code:strUUID()
                  // country: row[5],           
                };
                const temp ={
                  amount: row[3],
                  mobile: row[2],
                  operator: row[4],
                  countryCode:selectedCountry.value
                }
                // importArray.push(user);
                transactionsJson.push(user);
                // transactionsJson.push(temp);
                if(checkFormat(row[3])==0){
                  totalAmount+= parseInt(row[3]);
                }
                //(i>0)? :''
                i++;
              //  }else{              
              //     errors.value='Données incorrectes dans le fichier';              
              //     errorId.value='Ligne '+(i+1);
              //  }
             
           
          }
         
           
        }
        
        
        // i = 0;
        //i-1;
        //control amount format
        let tempAmountResultArr = getBeneficiaryWithIncorrectAmount(transactionsJson);
        amountResult = tempAmountResultArr[0];
        console.log(tempAmountResultArr[1]) 
        let tempTelSizeResultArr = getBeneficiaryWithIncorrectTelSize(transactionsJson,phoneMask);
        telSizeResult = tempTelSizeResultArr[0]; 
        let temptelFormatResultArr = getBeneficiaryWithIncorrectTelFormat(transactionsJson); 
        telFormatResult = temptelFormatResultArr[0];         
        let tempTelOperatorResultArr = getBeneficiaryWithIncorrectTelOperatorName(transactionsJson,operators.value);
        telOperatorResult = tempTelOperatorResultArr[0];         
        incorrect_amount =  tempAmountResultArr[1];
        incorrect_telSize =  tempTelSizeResultArr[1]; 
        incorrect_telFormat = temptelFormatResultArr[1];
        incorrect_telOperator = tempTelOperatorResultArr[1];
        consolidate_error()
        // console.log(incorrect_telOperator)

        let tempDuplicateTelResultArr = checkDuplicateTel(transactionsJson);  
        duplicateTelResult = tempDuplicateTelResultArr[0];        
        // operators.value     
        total.value=i-tempDuplicateTelResultArr[0];
        //alert(telOperatorResult);
        totalChecking = amountResult+telSizeResult+telFormatResult+telOperatorResult+tempDuplicateTelResultArr[0];
        // transferts.value = transactionsJson;
        // totalTransfertAmount.value = totalAmount-tempDuplicateTelResultArr[1];
        transferts.value = transactionsJson.concat(transactionsJson1);  //transferts.value =           
        totalTransfertAmount.value = totalAmount+totalAmount1-tempDuplicateTelResultArr[1];
        // alert(tempDuplicateTelResultArr[1]);
        
        
      };
      loader.value=true;
      
     
      // showToast("Importé avec succès");
      reader.readAsArrayBuffer(file);
     
        document.getElementById("toastElement").click();
        setTimeout(() => {
          select_error_field()
        }, "1000");
        
      //  alert('ok')     
}

function consolidate_error(){
  
  let arrayTemp = [];
  for (const row of incorrect_amount) { 
    
    if(!arrayTemp.find(el => el.code == row.code)){
      arrayTemp.push(row);
    }  
  }
  for (const row of incorrect_telSize) { 
    if(!arrayTemp.find(el => el.code == row.code)){
      arrayTemp.push(row);
    }  
  }
  for (const row of incorrect_telFormat) { 
    if(!arrayTemp.find(el => el.code == row.code)){
      arrayTemp.push(row);
    }  
  }
  for (const row of incorrect_telOperator) { 
    if(!arrayTemp.find(el => el.code == row.code)){
      arrayTemp.push(row);
    }    
  }
  incorrect_total = arrayTemp;

}

function select_error_field(){
  // console.log(incorrect_amount[0].code);
  
  for (const row of incorrect_amount) { 
    document.getElementById(row.code+":amount").style.borderColor='red';  
    document.getElementById(row.code+":amount")?.removeAttribute("readonly");    
  }
  for (const row of incorrect_telSize) { 
    document.getElementById(row.code+":mobile").style.borderColor='red';   
    document.getElementById(row.code+":mobile")?.removeAttribute("readonly"); 
  }
  for (const row of incorrect_telFormat) { 
    document.getElementById(row.code+":mobile").style.borderColor='red';  
    document.getElementById(row.code+":mobile")?.removeAttribute("readonly");
  }
  for (const row of incorrect_telOperator) { 
    document.getElementById(row.code+":operator").style.borderColor='red';  
    document.getElementById(row.code+":operator").disabled=false;   
  }
  

}

function handleAmountFixed(myvalue,tag){
  let amountTemp = tag.id.split(":");  
  for (const row of incorrect_total) { 
    if(amountTemp[0] == row.code){
      row.amount = myvalue;
    }    
  }
  for (const row of incorrect_amount) { 
    if(amountTemp[0] == row.code){
      row.amount = myvalue;
    } 
  }
  errorId.value=''
  errors.value=''
  document.getElementById(amountTemp[0]+":amount").style.borderColor=''; 
}

function handleTelFixed(myvalue,tag){
  let mobileTemp = tag.id.split(":");  
  for (const row of incorrect_total) { 
    if(mobileTemp[0] == row.code){
      row.mobile = myvalue;
    }    
  }
  
  errorId.value=''
  errors.value=''
  document.getElementById(mobileTemp[0]+":mobile").style.borderColor=''; 
}

function handleOperatorFixed(event){
  let operatorTemp = event.target.value.split(":");  
  for (const row of incorrect_total) { 
    if(operatorTemp[0] == row.code){
      row.operator = operatorTemp[1];
    }    
  }
  
  errorId.value=''
  errors.value=''
  document.getElementById(operatorTemp[0]+":operator").style.borderColor=''; 
}

function addBeneficiaryfixed(){
  btnLoad.value=false;
  // console.log(incorrect_total);
  // let arrayTemp = transferts.value;
  // let arrayTemp = transactionsJson;
  // transferts.value = [];
  // for (const row of incorrect_total) { 
    //  if(arrayTemp.find(el => el.code == row.code)){
      //  let i = arrayTemp.findIndex(el => el.code == row.code);
      // let i = arrayTemp.indexOf(row);
      // delete arrayTemp[i];       
      // arrayTemp.push(row);
    //  }    
  // }
  // console.log(arrayTemp);
  //transactionsJson.concat(transactionsJson1);
  // transferts.value = arrayTemp;
  
  // alert(totalTransfertAmount.value);
  let tempAmount = totalTransfertAmount.value;
  for (const row of incorrect_amount) {    
      
    tempAmount += parseInt(row.amount);
  }
  totalTransfertAmount.value = tempAmount;
  totalChecking = 0;  
  amountResult = 0;
  telSizeResult = 0;
  telFormatResult = 0;
  telOperatorResult = 0;  
  duplicateTelResult = 0;
   
  btnLoad.value=true;
  document.getElementById("close-fix-modal").click();
}







</script>
<style scoped>
#title{
 text-decoration: underline;
 margin-bottom: 2%;
}
#transactionsTable{
 display: none; 
 /* visibility: hidden;*/
}
#sub_business{
  display: none; 
}
</style>