import axios from 'axios';
import router from '../router/index'
import { Country,Fee,Operator,Organization,TypeFree } from "@/utils/Model";
let typefees =new Array<TypeFree>();
// const token: string|null = localStorage.getItem('login_token');
// token,router Array<T>
// export const getTypeFee = async  (token): Array<TypeFree> =>{
export const getTypeFee =  async  (token): Promise<Array<TypeFree>> =>{
    
      //let typefees =new Array<TypeFree>();
       
    //let typefees:TypeFree[]
    // let typefees:TypeFree[]| any = any;
    // let typefees=[];
    //  let data = [];
    
    axios.get(process.env.VUE_APP_API_BASE+'/secure/typefees/',{  
    headers: {
       
        Authorization: `Bearer ${token}`
    } 
    })
    .then((response) => { 
      typefees = response.data;    
      // typefees.push(response.data[0]) //= response.data[0];  //.data.users       
      
        alert(typefees[0].code) 
        return Promise.resolve(typefees);
      // console.log(typefees[0].code); 
     
      // data = response.data;  
        // return response.data;      
      //  console.log(data[3]);
    
    }).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')        
          }   
          
      }
    });
    //  alert(typeof typefees)
    //  console.log(data[1]);
    // console.log(typefees[0].id);
    // return  Promise.resolve(data);
    //alert(typefees[0].code) ;
   return  Promise.resolve(typefees);
}

export function redirect(arg){
  router.push(arg);
}