<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <button
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#companyModal"
          style="background-color: #EB9355;"
          @click="initField"
        >
          Créer un client
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
        
      </div>
      <div class="d-flex align-items-center">
        <!-- <form class="search-box position-relative me-15">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Search customer"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <button
          class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
          type="button"
        >
          <i class="flaticon-dots"></i>
        </button> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Customer Name
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Banque
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                RIB
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Cryptomonnaie
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                EMAIL
              </th>
              <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                PHONE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Received
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Due
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Last Login
              </th> -->
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                STATUS
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr   v-for="(organization) in organizations" :key="organization.id">
              <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
              >
                <div class="d-flex align-items-center">
                  <div class="form-check mb-0">
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                    />
                  </div>
                  <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                    <img
                      src="../../../assets/images/user/user3.jpg"
                      class="rounded-circle me-8"
                      width="24"
                      height="24"
                      alt="user"
                    />
                    {{ organization.name }}
                  </div>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ organization.bankDomiciliation }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ organization.rib }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ organization.cryptoAccount }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ organization.email }}
              </td>
              <!-- <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                +1-222-463-6429
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                $ 100.00 USD
              </td>
              <td class="shadow-none lh-1 fw-medium text-muted">$ 00.00 USD</td>
              <td class="shadow-none lh-1 fw-medium text-muted">
                {{ formatDateFr(new Date(organization.created_at))}}
              </td> -->
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-success" v-if="organization.status==='ACTIVE'">Actif</span>
                <span class="badge text-outline-danger" v-if="organization.status==='INACTIVE'">Inactif</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <!-- <li>
                      <router-link
                        :to="`/transactions/${organization.code}`"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Transactions</router-link>
                    </li> -->
                    <li>
                      <router-link
                        :to="`/utilisateurs/${organization.code}`"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Utilisateurs</router-link>
                    </li>
                    <li>
                      <router-link
                        :to="`/account/${organization.code}`"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Comptes</router-link>
                    </li>
                    <li>
                      <router-link
                        :to="`/fees/${organization.code}`"
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Frais</router-link>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        @click="changeStatus(organization.status,organization.code)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        <span v-if="organization.status==='ACTIVE'">Désactiver</span>
                        <span v-if="organization.status==='INACTIVE'">Activer</span>
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        data-bs-target="#companyModal"
                        @click="showOrganization(organization.code)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Modifier</a
                      >
                    </li>
                   <!--  <li>
                      <a
                        class="dropdown-item d-flex align-items-center"
                        href="javascript:void(0);"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li> -->
                  </ul>
                </div>
              </td>
            </tr>
                      
          </tbody>
        </table>
      </div>
      <div
      v-if="total>0" class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
      >
        <p v-if="total>10" class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ page*10 }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <p v-if="total<=10"  class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ total }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0" class="page-item">
              <a class="page-link" @click="getOrganizations(page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="getOrganizations(numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"  class="page-item">
              <a class="page-link"  @click="getOrganizations(page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

   
  <div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="save">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Creation d'un client</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Raison sociale
                                  </label>              
                                  <div >                                     
                                          <input
                                            v-model="formValues.name"
                                            type="text"
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                            id="lead-calendar"
                                            placeholder="I-MONEY AFRICA"
                                            @blur="validate('name')"
                                            @keyup="validate('name')"
                                            :class="{ 'is-invalid': errorsBis['name'] }"
                                          />
                                          <span class="invalid-feedback">{{ errorsBis['name']}}</span> 
                                  </div>
                              </div>
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    Téléphone 
                                  </label>
                                  <div >
                                          <!-- <i class="flaticon-calendar lh-1 icon"></i> -->
                                          <input
                                            v-model="formValues.contact"
                                            type="text"
                                            class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                            id="lead-calendar"
                                            placeholder="ex: 0500457801"
                                            @blur="validate_phone_number($event.target.value,$event.target)"
                                            @keyup="validate_phone_number($event.target.value,$event.target)"
                                            @change="validate('contact')"
                                            :class="{ 'is-invalid': errorsBis['contact'] }"
                                          />
                                          <span class="invalid-feedback">{{ errorsBis['contact']}}</span> 
                                  </div>
                              </div>
                        </div>

                          <div class="row">
                          
                              <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    E-mail
                                  </label>
                                  <div >
                                                                      
                                        <input
                                        v-model="formValues.email"
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          id="lead-calendar"
                                          placeholder="ex: info@imoney.africa"
                                          @blur="validate('email')"
                                          @keyup="validate('email')"
                                          :class="{ 'is-invalid': errorsBis['email'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['email']}}</span> 
                                  </div>
                              </div>
                             <div class="col-md-6">
                                  <label class="d-block text-black fw-semibold mb-10">
                                    compte de Criptomonaie
                                  </label>
                                  <div >
                                                                      
                                        <input
                                        v-model="formValues.cryptoAccount"
                                          type="text"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          id="lead-calendar"
                                          placeholder="ex: info@imoney.africa"
                                          @blur="validate('cryptoAccount')"
                                          @keyup="validate('cryptoAccount')"
                                          :class="{ 'is-invalid': errorsBis['cryptoAccount'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['cryptoAccount']}}</span> 
                                  </div>
                              </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                Banque 
                              </label>
                              <div >
                                      <!-- <i class="flaticon-calendar lh-1 icon"></i> -->
                                      <input
                                        v-model="formValues.bankDomiciliation"
                                        type="text"
                                        class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                        id="lead-calendar"
                                        placeholder="ex: ECOBANK"
                                        @blur="validate('bankDomiciliation')"
                                        @keyup="validate('bankDomiciliation')"
                                        @change="validate('bankDomiciliation')"
                                        :class="{ 'is-invalid': errorsBis['bankDomiciliation'] }"
                                      />
                                      <span class="invalid-feedback">{{ errorsBis['bankDomiciliation']}}</span> 
                              </div>
                          </div>
                          <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                RIB
                              </label>
                              <div >
                                                                   
                                    <input
                                    v-model="formValues.rib"
                                      type="text"
                                      class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                      id="lead-calendar"
                                      placeholder=""
                                      @blur="validate('rib')"
                                      @keyup="validate('rib')"
                                      :class="{ 'is-invalid': errorsBis['rib'] }"
                                    />
                                    <span class="invalid-feedback">{{ errorsBis['rib']}}</span> 
                              </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                                    <label class="d-block text-black fw-semibold mb-10">
                                      Nécessite une validation 
                                    </label>              
                                    <select  name="operator" id="operator" class="form-select fs-md-15 text-black shadow-none" @change="handleValidationChange" required>
                                        <option value="N" :selected="selected" >Non</option>                 
                                        <option value="O" :selected="selected">Oui</option> 
                                    </select>
                            </div>
                        </div>
                        <div class="col-md-12">
                              <label class="d-block text-black fw-semibold mb-10">
                                Description
                              </label>              
                              <div >                                     
                                      <textarea
                                        v-model="formValues.description"
                                        type="text"
                                        class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                        id="lead-calendar"
                                        placeholder="Saisir la description de l'entreprise ici"
                                        @blur="validate('description')"
                                        @keyup="validate('description')"
                                        :class="{ 'is-invalid': errorsBis['description'] }"
                                      >
                                    </textarea>
                                    <span class="invalid-feedback">{{ errorsBis['description']}}</span>  
                              </div>
                          </div>
                    </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  Enregister
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
<div id="snackbar"></div>
</template>

<script setup  lang="ts">
import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import conf from '../../../../env.json'
import router from '../../../router/index'
import { useRouter } from 'vue-router';
import * as yup from 'yup';
import {validate_phone_number,showToast,checkSessionValidate} from "../../../utils/function";
import {Organization} from "@/utils/Model";
const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);
const gettran = ref(false);
const search = ref(false);
const search1 = ref(false);
const selectedValidation = ref('N');
const selected = ref(false);
let edit = 0;
let itemCode = '';
const errorId=ref('');
let token: string|null = localStorage.getItem('login_token');

function handleValidationChange(event) {
  selectedValidation.value = event.target.value;
}
 
  const errors = ref('');  
  const success = ref('');
  const errorsBis = {'name':'','description':'','email':'','contact':'','bankDomiciliation':'','rib':'','cryptoAccount':''};
  const pageLoad = ref(false);

  const formValues = reactive({
    email: "",  
    description: "",  
    name: "",  
    contact: "",
    bankDomiciliation:"",
    rib:"",
    cryptoAccount:""
  });

  const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object({    
      email: yup.string().email('l\'e-mail doit être un e-mail valide').required('L\'email est requis'),
      name: yup.string().required('La raison sociale est requise'),   
      description: yup.string().required('La description est requise'),  
      contact: yup.string().required('Le numéro de téléphone est requis').matches(phoneRegExp,"Veuillez saisir un numéro de telephone valide").test('len', "Le numero de téléphone doit faire exactement 10 chiffres", val => val.length === 10),  
      bankDomiciliation: yup.string().required('Le nom de la banque est requis'),  
      rib: yup.string().required('Le RIB est requis'),  
      cryptoAccount: yup.string()   
  });


const organizations =ref(new Array<Organization>());
  function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}

const showScreen = (code)=>{ 
  itemCode=code;
  
}

const initField = () =>{
  formValues.name=  '';  
  formValues.contact= ''; 
  formValues.email= ''; 
  formValues.description= '';
  formValues.bankDomiciliation= '';
  formValues.rib= '';
  formValues.cryptoAccount= '';
  selectedValidation.value = 'N';
  selected.value=false;
  itemCode = '';
  edit=0;
}

const cancel = () =>{
  itemCode="";
  errorId.value="";
  errors.value="";
}

const save =()=>{
  if (edit==0){
    storeOrganization()
  }else{
    updateOrganization()
    
  }
  
}


const showOrganization = (code) =>{
    // +arg
     axios.get(backApi+'/secure/organizations/'+code+'/show',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        formValues.name =  response.data.name;  
        formValues.description =  response.data.description; 
        formValues.contact =  response.data.contact; 
        formValues.email =  response.data.email; 
        formValues.bankDomiciliation =  response.data.bankDomiciliation; 
        formValues.rib =  response.data.rib; 
        formValues.cryptoAccount =  response.data.cryptoAccount; 
        selectedValidation.value=(response.data.validatePayoutBeforProcess)?'O':'N';
        selected.value = response.data.validatePayoutBeforProcess;
        itemCode = response.data.code;        
        edit=1;        
       
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                console.log(error.response);
              }   
              
          }
  });
}

const getOrganizations = (arg=1) =>{
    axios.get(backApi+'/secure/organizations/'+arg,{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        organizations.value = response.data.organizations;  //.data.users            
        
        total.value=parseInt(response.data.pagination.total);
        page.value=parseInt(response.data.pagination.page);
        pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        currentPage.value=parseInt(response.data.pagination.currentPage);
        numPages.value=parseInt(response.data.pagination.numPages);
        
        // alert(total.value)
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const changeStatus =(status,code)=>{
  axios.put(`${backApi}/secure/organizations/${code}/status`,{
          organization: code,
          status:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {  
        if(response.status === 200){     
          showToast("Organisation activée avec succès","green"); 
          router.go();  
        }             
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
             
              showToast(error.response.data.message,"red"); 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const changevalidatePayout =(status,code)=>{
  axios.put(`${backApi}/secure/payout-validation-process`,{
          // organization: code,
          validatePayoutBeforProcess:  (status=='ACTIVE')?'INACTIVE':'ACTIVE'
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {  
        if(response.status === 200){     
          showToast("Organisation activée avec succès","green"); 
          router.go();  
        }             
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
             
              showToast(error.response.data.message,"red"); 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const storeOrganization =()=>{
try {  
  schema.validateSync(formValues);
  axios.post(`${backApi}/secure/organizations/new`,{
          name:formValues.name,
          email:formValues.email,
          contact:formValues.contact,
          description:formValues.description,
          bankDomiciliation:formValues.bankDomiciliation,
          rib:formValues.rib,
          cryptoAccount:formValues.cryptoAccount,
          validatePayoutBeforProcess:(selectedValidation.value=='N')?false:true
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){     
          showToast("Enregistrement effectué avec succès","green"); 
          router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}

// name:name.value,
// email:email.value,
// contact:contact.value,
// description:description.value
}

const updateOrganization =()=>{
try {  
  schema.validateSync(formValues);
  axios.put(`${backApi}/secure/organizations/${itemCode}/edit`,{
          code:itemCode,
          name:formValues.name,
          email:formValues.email,
          contact:formValues.contact,
          description:formValues.description,
          bankDomiciliation:formValues.bankDomiciliation,
          rib:formValues.rib,
          cryptoAccount:formValues.cryptoAccount,
          validatePayoutBeforProcess:(selectedValidation.value=='N')?false:true
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){     
          showToast("Modifiaction effectuée avec succès","green"); 
          router.go();  
        }      
        
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
} catch (error) {
        console.error(error);        
         errors.value="une erreur inattendue est apparue"
      
}

}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}


onMounted(() => { 
  checkSessionValidate(1)
  getOrganizations();
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)
});
</script>