<template>
  <div class="clearfix"  v-if="!pageLoad">
      <div class="spinner-grow text-danger " style="width: 3rem; height: 3rem;margin-top:25vh; margin-left:42%; margin-bottom:5vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  </div>
  <div v-if="pageLoad" class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <button
              class="default-outline-btn text-black bg-transparent position-relative transition fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
              type="button"
              @click="exportExcel"
              
            >
            <!-- text-black bg-transparent -->
              Excel
              <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
            </button>

        
      </div>
      <div class="d-flex align-items-center">

           
            <button
              class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#companyModal"
              style="background-color: #EB9355;"
              @click="initField"
            >
              Faire un rechargement
              <i class="flaticon-plus position-relative ms-5 fs-12"></i>
            </button>


      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
              Code 
            </th> 
            <th  v-if="current_permissions('SUPERADMIN')"
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Organisation
              </th> 
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Description
              </th> 
             
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Montant
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Date
              </th>
              <th scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              RATTACHÉ À
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Status
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>
            <tr   v-for="(reloading) in reloadings" :key="reloading.code">
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.code }}
              </td> 
              <td v-if="current_permissions('SUPERADMIN')" class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.organization.name }}
              </td>              
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.description }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.amount }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">                
                {{ formatDateFr(new Date(reloading.createdAt))}}
              </td> 
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ reloading.user?.name }}
              </td>                       
              <td class="shadow-none lh-1 fw-medium">
                <span class="badge text-outline-info" v-if="reloading.status==='DRAFT'">En attente</span>
                <span class="badge text-outline-success" v-if="reloading.status==='VALIDATED'">Validé</span>
                <span class="badge text-outline-danger" v-if="reloading.status==='REJECTED'">Rejeté</span>
              </td>
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                   
                    <li  v-if="reloading.status==='DRAFT' && current_permissions('SUPERADMIN')" >
                      <a
                        class="dropdown-item d-flex align-items-center"  style="color: red;"
                        href="javascript:void(0);"
                        @click="changeStatus('REJECTED',reloading.code)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        <span   class="">Réjeté</span>
                        
                      </a>
                    </li> 
                    <li  v-if="reloading.status==='DRAFT' && current_permissions('SUPERADMIN')">
                      <a
                        class="dropdown-item d-flex align-items-center" style="color: green;"
                        href="javascript:void(0);"
                        @click="changeStatus('VALIDATED',reloading.code)"
                        >
                        <i
                          class="flaticon-pen lh-1 me-8 position-relative top-1 "
                        ></i>

                        <span  class="">Validé</span>                       
                      </a>
                    </li>  
                    <li  v-if="reloading.status==='DRAFT'" >
                      <a
                        class="dropdown-item d-flex align-items-center"  style="color: red;"
                        href="javascript:void(0);"
                        @click="showReloading(reloading.code)"
                        data-bs-toggle="modal"
                        data-bs-target="#companyModal"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                       
                        <span   class="">Modifier</span>
                        <!-- USER  && current_permissions('SUPERADMIN') -->
                      </a>
                    </li>                           
                  </ul>
                </div>
              </td>
            </tr>
                      
          </tbody>
        </table>
      </div>
      <div
      v-if="total>0" class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
      >
        <p v-if="total>10" class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ page*10 }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <p v-if="total<=10"  class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ total }}</span> out of
          <span class="fw-bold">{{ total }}</span> resultats
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li  v-if="(page-1)>0" class="page-item">
              <a class="page-link" @click="getReloadings(page-1)" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
           
            <li v-for="(numPage) in numPages" :key="numPage" class="page-item">              
              <a  v-if="numPage==page" class="page-link  active" href="#">{{ numPage}}</a>
              <a v-else class="page-link" @click="getReloadings(numPage)">{{ numPage}}</a>
            </li>
           
            <li v-if="(page+1)<=numPages"  class="page-item">
              <a class="page-link"  @click="getReloadings(page+1)" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

   
  <div class="modal fade" id="companyModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">     
        <div class="modal-content">
           <form  @submit.prevent="save">
            <div class="modal-header">
                <h1 class="modal-title fs-5">Demande de rechargement</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                      <!-- <div  style="margin-bottom: 5%;">
                        <h6 class="text-black fw-bold mb-0 text-center">{{ 'SOLDE'}}</h6>
                        <h6 class="text-black fw-bold mb-0 text-center" style="color: red;">{{ collectBalance.toLocaleString()}} XOF</h6>
                      </div> -->
                      <div v-if="errors!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorId}}</h6>
                        <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>
                      </div>
                      <div v-if="success!==''" style="margin-top: 5%;">
                        <h6 class="text-red fw-bold mb-0 text-center" style="color:green">{{ success}}</h6>
                      </div>
                      <div class="form-group mb-15 mb-sm-20 mb-md-25">
                        <div class="row">                            
                            <!--  -->
                            <div class="col-md-6">
                                <label class="d-block text-black fw-semibold mb-10">
                                  Montant
                                </label>              
                                <div >                                     
                                        <input @keyup="handleAmountChange" 
                                          v-model="formValues.amount"
                                          type="number"
                                          class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                          id="lead-calendar"
                                          placeholder="2"
                                          @blur="validate('amount')"                                          
                                          :class="{ 'is-invalid': errorsBis['amount'] }"
                                        />
                                        <span class="invalid-feedback">{{ errorsBis['amount']}}</span> 
                                </div>
                            </div>
                            <div class="col-md-6">
                              <label class="d-block text-black fw-semibold mb-10">
                                  Utilisateurs
                                </label>              
                                <select  name="mode" id="mode" class="form-select fs-md-15 text-black shadow-none" @change="handleUserChange" >
                                    <option value="">Selectionnez...</option>                 
                                    <option v-for="user in users" :value="user.code" :key="user.code"> {{ user.name }}</option>
                                </select>
                                
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                                    <label class="d-block text-black fw-semibold mb-10">
                                      Description
                                    </label>              
                                    <div >                                     
                                            <textarea
                                              v-model="formValues.description"
                                              type="text"
                                              class="form-control flatpickr shadow-none fw-medium text-black fs-md-15 fs-lg-16"
                                              id="lead-calendar"
                                              placeholder="Saisir la description de l'entreprise ici"
                                              @blur="validate('description')"
                                              @keyup="validate('description')"
                                              :class="{ 'is-invalid': errorsBis['description'] }"
                                            >
                                          </textarea>
                                          <span class="invalid-feedback">{{ errorsBis['description']}}</span>  
                                    </div>
                              </div>                            
                        </div>
                    

                    </div>
            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                <button type="submit" class="btn  text-white" style="background-color: #EB9355;" >
                  <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                  Enregister
                </button>
            </div>
            </form>
        </div>
      
    </div>
</div>
</template>

<script setup  lang="ts">
import {ref, onMounted,reactive,inject } from "vue";
import axios from 'axios';
import router from '../../router/index'
import { useRouter } from 'vue-router';
import * as yup from 'yup';
import { Country,Fee,Operator,Organization,TypeFree,Reloading, User } from "@/utils/Model";
import { getTypeFee } from "../../utils/services";
import {current_permissions} from '../../utils/permission';
import {checkSessionValidate} from '../../utils/function';
const backApi=inject('back');
const page = ref(1);
const total = ref(0);
const pagesLeft = ref(0);
const currentPage = ref(0);
const numPages = ref(0);


const selectedOperator = ref('');
const selectedCountry = ref('');
const selectedType = ref('');
const selectedMode = ref('');
const selectedUser = ref('');
const users = ref(new Array<User>());

const loader = ref(false);

let token: string|null = localStorage.getItem('login_token');
let codeOrganization: string|null = localStorage.getItem('organization_code');
let codeUser: string|null = localStorage.getItem('code');
let role: string|null = localStorage.getItem('role');


const countries =ref(new Array<Country>());
const reloadings =ref(new Array<Reloading>());
// const reloading =ref({} as Reloading);
let edit = 0;
let itemCode = '';
let selectedOrganization = '';
let user = {} as User;
const operators =ref(new Array<Operator>());
  const collectBalance =ref(0);
  let balance:number = 0;
// const typefees =ref([]);
const typefees =ref(new Array<TypeFree>());
let operatorsTemp =new Array<Operator>();
let countriesTemp =new Array<Country>();
  let data = [];
 
  const errors = ref('');  
  const errorId = ref('');  
  const success = ref('');
  const errorsBis = {'amount':'','description':''};
  const pageLoad = ref(false);

  const formValues = reactive({    
    amount: "",  
    description: ""   
  });

  
  const schema = yup.object({    
      amount: yup.number().required('Le montant est requis'),  
      description: yup.string().required('La description est requise'),     
  });

  function handleAmountChange(event) {    
      let withdrawAmount = event.target.value;
      if(parseFloat(withdrawAmount)<=0){
        formValues.amount=''
      }
    }


function handleTypeChange(event) {
  selectedType.value = event.target.value;
}
function handleUserChange(event) {
  selectedUser.value = event.target.value;
}

function formatDateFr(value){
    if(value){
        const year = value.getFullYear();
        let month =value.getMonth() + 1;
        month = month<10?'0'+month:month;
        let day = value.getDate();  
        day = day<10?'0'+day:day;      
        return day+'/'+month+'/'+year;
        
    }
}
const initField = () =>{
  formValues.amount=  '';  
  formValues.description= ''; 
  itemCode = '';
  selectedOrganization = '';
  edit=0;
}


const getUsers = (code) =>{

axios.get(`${backApi}/secure/users/?organization=${code}`,{
      headers: {
          Authorization: `Bearer ${token}`
    }
  })
  .then((response) => {
      // users.value = response.data;
      users.value = response.data;  //.data.users          
      // console.log(users.value);
      
      
  }).catch((error) => {
        if( error.response){
            console.log(error.response.data); // => the response payload 
            if(error.response.status === 401){
              router.push('/')
              //window.location.href = '/';
            }   
            
        }
});
}

const getReloadings = (arg=1) =>{
    // +arg
    let param = '';
    param = (role=='SUPERADMIN')?'':`?organization=${codeOrganization}`
    axios.get(backApi+'/secure/topups/'+param,{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        reloadings.value = response.data;  //.data.reloadings            
        data  = response.data;  
        // total.value=parseInt(response.data.pagination.total);
        // page.value=parseInt(response.data.pagination.page);
        // pagesLeft.value=parseInt(response.data.pagination.pagesLeft);
        // currentPage.value=parseInt(response.data.pagination.currentPage);
        // numPages.value=parseInt(response.data.pagination.numPages);

        total.value=data.length;
        page.value=1;
        pagesLeft.value=0;
        currentPage.value=1;
        numPages.value=1;
        
        // alert(total.value)
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const showReloading = (code) =>{
    // +arg
     axios.get(backApi+'/secure/topups/'+code+'/show',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        // reloading.value = response.data;  //.data.reloadings 
        formValues.amount=  response.data.amount;  
        formValues.description=  response.data.description; 
        itemCode = response.data.code;
        selectedOrganization = response.data.organization.code;
        edit=1;        
        console.log(response.data);
    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                console.log(error.response);
              }   
              
          }
  });
}

const getBalanceCollect = () =>{

axios.post(backApi+`/secure/accounts/balance/available`,{//collect//collectBalance
          code: codeOrganization,         
        },{  
        headers: {
            
              Authorization: `Bearer ${token}`
        } 
})
.then((response) => {    
    collectBalance.value = response.data.availableBalance;  //.data.users  
    balance = response.data.availableBalance;       
    console.log(response.data);
    
    
}).catch((error) => {
      if( error.response){
          console.log(error.response.data); // => the response payload 
          if(error.response.status === 401){
            router.push('/')
            //window.location.href = '/';
            //router.push('/');
          }   
          
      }
});
//  loader.value=true;
}




const changeStatus =(status,code)=>{
 // alert(code)
  axios.put(`${backApi}/secure/topups/status`,{
        code:code,      
        status:  status
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
      }       
      }).then((response) => {        
        router.go();        
        
    }).catch((error) => {
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}

const save =()=>{
  if (edit==0){
    storeReloading()
  }else{
    updateReloading()
    
  }
  
}

const storeReloading =()=>{
try {  
  schema.validateSync(formValues);
  // alert(formValues)
  axios.post(`${backApi}/secure/topups/new`,{          
          amount:parseFloat(formValues.amount),
          description:formValues.description,
          organization:codeOrganization,//,//user.organization.code,         
          user:(selectedUser.value=='')?null:selectedUser.value,
          // type:selectedType.value

        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

// name:name.value,
// email:email.value,
// contact:contact.value,
// description:description.value
}

const updateReloading =()=>{
try {  
  schema.validateSync(formValues);
  // alert(formValues)
  axios.put(`${backApi}/secure/topups/${itemCode}/edit`,{          
          amount:parseFloat(formValues.amount),
          description:formValues.description,
          code:itemCode,       
          organization:selectedOrganization 
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {
        edit=0;   
        itemCode='';      
        selectedOrganization ='';
        if(response.status === 200){      
          router.go();  
        }      
        
    }).catch((error) => {
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

}

function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value=''
}

const exportExcel = () =>{
  
  import("../../utils/Export2Excel").then((excel)=>{
    // const data = transactions.value;
    const Header =["CODE RECHARGEMENT","DESCRIPTION","MONTANT","DATE","STATUT"];
    const Fields = ["code","description","amount","createdAt","status"];//mnoName
    // console.log(data);
    const dataForExcel= formatJSon(Fields,data);
    // console.log(dataForExcel);
    excel.export_json_to_excel({     
      header:Header,
      data:dataForExcel,
      sheetName:"Feuil1",
      filename:"rechargement",      
      autoWidth: true,
      bookType:"xlsx",
  });
  });
}

function formatJSon(FilterData,JsonData){
  return JsonData.map((v)=>FilterData.map(j=>{
      return v[j];
    }));
}


 onMounted(async () => { 
  checkSessionValidate(1)
   getReloadings();
   getBalanceCollect();
   getUsers(codeOrganization);
  //  getOperators();
  //  getOrganizations();
  
   setTimeout(function () {
    pageLoad.value=true; 
         },1000)
});
</script>