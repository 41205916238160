<template>
<div class="row">
  <div class="col-lg-6">
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
        <h5 class="card-title fw-bold mb-15 mb-lg-20">Configuration de l'url de notification</h5>
        <div class="border p-2 p-lg-4 border-dark border-opacity-10">
          <form id="callback"   @submit.prevent="storeUrl" class="row g-3 needs-validation" novalidate>
            <div v-if="errors!==''" style="margin-top: 5%;">
                <h6 class="text-red fw-bold mb-0 text-center" style="color: red;"> {{ errorId}}</h6>
                <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errors}}</h6>              
                        
            </div>
            <div class="col-12">
              <label for="validationCustom01" class="form-label fw-medium"
                >Url</label
              >
              <input
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                id="validationCustom01"
                placeholder="entrez l'url de notification ici"
                value=""
                required
                v-model="formValues.url" 
                :class="{ 'is-invalid': errorsBis['url'] }"
                @blur="validate('url')"
              />
              <!-- <div class="valid-feedback"></div> -->
              <span class="invalid-feedback">{{ errorsBis['url']}}</span> 
            </div>
            <div class="col-3">

            </div>
           
            <div class="col-5">
              <button :disabled="!btnLoad1" class="btn btn-primary" type="button" @click="generateSecretKey">
                <span v-if="!btnLoad1" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>                
                Générer votre secret key
              </button>
            </div>
            <div class="col-4">
              <button :disabled="!btnLoad" class="btn text-white" type="submit" style="background-color: #EB9355;" >
                <span v-if="!btnLoad" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                Enregistrer l'url
              </button>
            </div>
          </form>
        </div>      
      </div>
    </div>
  </div>

  <div class="col-lg-6">
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
        <h5 class="card-title fw-bold mb-15 mb-lg-20">Configuration du logo</h5>
        <div class="border p-2 p-lg-4 border-dark border-opacity-10">
          <form id="callback1"   @submit.prevent="updateImage" class="row g-3" >
            <div v-if="errorsLogo!==''" style="margin-top: 5%;">
                <h6 class="text-red fw-bold mb-0 text-center" style="color: red;"> {{ errorIdLogo}}</h6>
                <h6 class="text-red fw-bold mb-0 text-center" style="color: red;">{{ errorsLogo}}</h6>              
                        
            </div>
            <div class="col-8">
              <label for="validationCustom01" class="form-label fw-medium"
                >Logo </label
              >
              <input
                type="file"
                class="form-control shadow-none fs-md-15 text-black"
                id="logo"
                @change="handleImageUpload"
                required
              />              
            </div>
            <div class="col-4" >
              <img v-if="image" :src="image" class="rounded-circle" style="">              
              <img v-if="!image" :src="imgSrc"  class="rounded-circle" style="">              
            </div>
           
            <div class="col-4">
              <button :disabled="!btnLoadLogo" class="btn text-white" type="submit" style="background-color: #EB9355;" >
                <span v-if="!btnLoadLogo" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"  style="color: #FFF;"></span>
                <i class="flaticon-plus position-relative ms-5 fs-12" style="color: #FFF;"></i> 
                Enregistrer le logo
              </button>
            </div>
          </form>
        </div>      
      </div>
    </div>
  </div>



  <!-- <div class="col-lg-6">
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
        <h5 class="card-title fw-bold mb-15 mb-lg-20">Custom Styles</h5>
        <div class="border p-2 p-lg-4 border-dark border-opacity-10">
          <form class="row g-3 needs-validation" novalidate>
            <div class="col-md-4">
              <label for="validationCustom01" class="form-label fw-medium"
                >First name</label
              >
              <input
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                id="validationCustom01"
                value="Mark"
                required
              />
              <div class="valid-feedback">Looks good!</div>
            </div>
            <div class="col-md-4">
              <label for="validationCustom02" class="form-label fw-medium"
                >Last name</label
              >
              <input
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                id="validationCustom02"
                value="Otto"
                required
              />
              <div class="valid-feedback">Looks good!</div>
            </div>
            <div class="col-md-4">
              <label for="validationCustomUsername" class="form-label fw-medium"
                >Username</label
              >
              <div class="input-group has-validation">
                <span class="input-group-text" id="inputGroupPrepend">@</span>
                <input
                  type="text"
                  class="form-control shadow-none fs-md-15 text-black"
                  id="validationCustomUsername"
                  aria-describedby="inputGroupPrepend"
                  required
                />
                <div class="invalid-feedback">Please choose a username.</div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="validationCustom03" class="form-label fw-medium"
                >City</label
              >
              <input
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                id="validationCustom03"
                required
              />
              <div class="invalid-feedback">Please provide a valid city.</div>
            </div>
            <div class="col-md-3">
              <label for="validationCustom04" class="form-label fw-medium"
                >State</label
              >
              <select
                class="form-select shadow-none fs-md-15 text-black"
                id="validationCustom04"
                required
              >
                <option selected disabled value="">Choose...</option>
                <option>...</option>
              </select>
              <div class="invalid-feedback">Please select a valid state.</div>
            </div>
            <div class="col-md-3">
              <label for="validationCustom05" class="form-label fw-medium"
                >Zip</label
              >
              <input
                type="text"
                class="form-control shadow-none fs-md-15 text-black"
                id="validationCustom05"
                required
              />
              <div class="invalid-feedback">Please provide a valid zip.</div>
            </div>
            <div class="col-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="invalidCheck"
                  required
                />
                <label
                  class="form-check-label fw-medium position-relative top-1"
                  for="invalidCheck"
                >
                  Agree to terms and conditions
                </label>
                <div class="invalid-feedback">
                  You must agree before submitting.
                </div>
              </div>
            </div>
            <div class="col-12">
              <button class="btn btn-primary" type="submit">Submit form</button>
            </div>
          </form>
        </div>      
      </div>
    </div>
  </div> -->
</div> 
<div class="modal fade" id="filterLeadModal" tabindex="-1" aria-hidden="true">
    <div  class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h1  class="modal-title fs-5">SECRET KEY</h1>                
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
            </div>
            <div class="modal-body">
                      <div style="margin-left: 10%" class="">
                        
                        <h6 style="width: 90%;"   class="fw-bold mb-0 " >Votre secret key :
                          <span class="text-success fw-bold mb-0 "  > {{ secretKey }}</span>&nbsp;&nbsp;&nbsp;
                          <a href="#" class="text-decoration-none" @click="copyToCliboard" style="float:right">                                      
                                  <i class="flaticon-draft fs-6" style="color: #000"></i>                                  
                                
                                </a>  
                        </h6> 
                                                                      
                      </div>
                      <hr/>
                      <div style="margin-left: 10%" class="">                        
                       <h6  class="fw-bold mb-0" >NB: Veuillez copier ce code et le garder pour pouvoir l'utiliser lors de vos intégrations. </h6>     
                        
                      </div>
            </div>
          </div>
    </div>
</div>
<button
    class=""
    type="button"
    style="background-color: #EB9355;visibility: hidden;" 
    data-bs-toggle="modal"
    data-bs-target="#filterLeadModal"     
    id="toastElement"      
>  
</button>       
<div id="snackbar"></div>
</template>

<script setup  lang="ts">

import {ref, onMounted,onBeforeMount,reactive,inject,computed } from "vue";
import * as yup from 'yup';
import axios from 'axios';
import {current_permissions} from '../../utils/permission';
import {redirect} from '../../utils/services';
import router from '../../router/index'
import { showToast,checkSessionValidate,img_src } from "@/utils/function";
import { Business,Logo } from "@/utils/Model";

const filename = ref('');
const image = ref('');
// const logo = ref({} as Logo);
const logo = ref(new Array<Logo>());
const secretKey = ref('');  
const backApi=inject('back');
const btnLoad = ref(true);
const btnLoad1 = ref(true);
const btnLoadLogo = ref(true);
const errorsLogo = ref('');  
const errorIdLogo = ref(''); 
const errors = ref('');  
const errorId = ref('');  
const success = ref('');
const errorsBis = {'url':''};
const formValues = reactive({    
    url: ""   
  });
  
  const schema = yup.object({     
    url: yup.string().required('L\'url de notification est requise'),     
    
  });
const token =  localStorage.getItem('login_token');
let codeOrganization: string|null = localStorage.getItem('organization_code');
onMounted(() => {
  checkSessionValidate(1);
  getCallBackUrl();
  getLogo();
});

const storeUrl =()=>{
  btnLoad.value=false;
try {  
  schema.validateSync(formValues);  
  axios.post(`${backApi}/secure/webhooks/url`,{          
          url:formValues.url,          
          organization:codeOrganization,
          
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        btnLoad.value=true;
        if(response.status === 200){ 
          console.log(response);          
          showToast("Enregistrement effectué avec succès","green");
          // router.go();  
        }      
        
    }).catch((error) => {
      btnLoad.value=true;
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
       btnLoad.value=true;
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

}

const generateSecretKey =()=>{
  btnLoad1.value=false;
try {  
    
  axios.post(`${backApi}/secure/webhooks/token`,{          
          url:formValues.url,          
          organization:codeOrganization,
          
        }, {  
      headers: {            
            Authorization: `Bearer ${token}`,
           'Content-Type':'application/json',
      }       
      }).then((response) => {  
        btnLoad1.value=true;
        if(response.status === 200){
          secretKey.value = response.data.webhookToken;
          document.getElementById("toastElement").click();
        }      
        
    }).catch((error) => {
      btnLoad1.value=true;
      // alert(error.response.data.errorId);
          if( error.response){
              //console.log(error.response.data); // => the response payload 
              if(error.response.status === 401){
                router.push('/')
                
              }else{
                errorId.value=error.response.data.errorId;
                errors.value=error.response.data.message;
              } 
              
          }
  });
} catch (error) {
       btnLoad1.value=true;
        // console.log(error);        
         errors.value="une erreur inattendue est apparue"
         
              
}

}

const getCallBackUrl = () =>{
   
    axios.get(backApi+'/secure/webhooks/show-webhook',{    
      headers: {
            
            Authorization: `Bearer ${token}`
      } 
    })
    .then((response) => {        
        // business.value = response.data;           
        formValues.url=response.data

    }).catch((error) => {
          if( error.response){              
              if(error.response.status === 401){
                router.push('/')
                
              }   
              
          }
  });
}
function validate(field) {
      schema
        .validateAt(field, formValues)
        .then(() => {
          errorsBis[field] = "";
        })
        .catch(err => {
          errorsBis[field] = err.message;          
        });
        errors.value='';
        success.value='';
}

function copyToCliboard() {
  navigator.clipboard.writeText(secretKey.value);
  showToast("Copié","green");
}


const handleImageUpload = (event) => {
  const file = event.target.files[0]
  // var base64 = btoa(event.target.result);
  // filename.value = base64;
  const fileSize = file.size/1024;
    if(fileSize<=parseInt(process.env.VUE_APP_IMG_SIZE)){
        filename.value = file;
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          image.value = reader.result
          console.log("images console->",file)
        }
    }else{        
        showToast("la taille maximum autorisée est "+process.env.VUE_APP_IMG_SIZE+" kb!","red");
        document.getElementById("logo").value='';
    }
  
}

const updateImage = () => {
    btnLoadLogo.value=false;
    const formdata = new FormData();
    formdata.append('file', filename.value);
    formdata.append('organization', codeOrganization);
    axios.post(backApi+'/secure/organizations/logo', formdata,{
        headers: {
            Authorization: `Bearer ${token}`
  }
    })
        .then((response) => {
          btnLoadLogo.value=true;
        if(response.status === 200){             
          logo.value =  response.data;   
          showToast("Enregistrement effectué avec succès","green");
          
        }   
        })
        .catch((error) => {
            if( error.response){              
                if(error.response.status === 401){
                  router.push('/')
                  
                }else{
                  errorIdLogo.value=error.response.data.errorId;
                  errorsLogo.value=error.response.data.message;
                }            
            }
        })
};

const getLogo = () =>{
   
   axios.get(backApi+`/secure/organizations/${codeOrganization}/logo`,{    
     headers: {
           
           Authorization: `Bearer ${token}`
     } 
   })
   .then((response) => {        
        logo.value = response.data;           
      //  formValues.url=response.data

   }).catch((error) => {
         if( error.response){              
             if(error.response.status === 401){
               router.push('/')
               
             }   
             
         }
 });
 
}

const imgSrc = computed(() => {
  const temp = logo.value;  
  return img_src(temp[0]?.storageUrl)  
})


</script>